
/* width */
html{
    /*overflow: hidden;*/
}
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/***/

.mapFlagNum{
    position: absolute;
    padding: 5px;
    border-radius: 0 15px 15px 0;
    width: 35px;
    height: 30px;
    text-align: center;
    font-weight: 600;
    font-size: 16pt;
    background-color: #ccc;
    left: -42px;
    top: 1px;
}

#animationMap{
    transition: opacity 0.5s
}

#mapMenu{
    border-radius: 10px;
    position: absolute;
    max-height: 500px;
    z-index: 999;
    right: 17px;
    top: -10px;
    background-color: #ffffff;
    padding: 15px 5px 5px 5px;
    box-shadow: 0px 0px 20px 0px #00000022;
    transition: all 0.5s;
    overflow: hidden;
    display: inline-flex;
}

#mapMenu div{
    padding: 15px;
    background-color: #aaaaaa11;
    border-radius: 10px;
    overflow: hidden;
    margin: 5px;
    cursor: 'pointer'
}

#mapLegend{
    border-radius: 10px;
    position: absolute;
    max-height: 500px;
    z-index: 999;
    left: 10px;
    top: 135px;
    background-color: #ffffff;
    padding: 15px;
    padding-top: 20px;
    box-shadow: 0px 0px 5px 0px #00000055;
    transition: all 0.5s;
    overflow: hidden;
}

#mapLegend.minimized{
    max-height: 500px;
}

#mapLegend .title{
    padding-top: 0;
    margin-top: 0;
    color: #777;
    font-size: 12pt;    
}

#mapLegend .minimize{
    position: absolute;
    opacity: 0;
    right: 15px;
    top: 13px;
    transition: all .5s;
}

@media (max-width: 992px) { 
 
}

@media (max-width: 768px) {  
 
}

@media (max-width: 576px) {  
    #mapLegend{
        border-radius: 5px;
        padding-top: 15px;
        width: Calc(100% - 30px);
        left: 15px;
        top: 135px;
    }

    #mapLegend .minimize{
        opacity: 1;
    }

    #mapLegend.minimized{
        max-height: 49px;
    }
}


 
